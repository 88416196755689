window.onscroll = function() {scrollFunction()};

  window.addEventListener("scroll", reveal);

    // To check the scroll position on page load
    // reveal();

      function scrollFunction() {   
        var x, checker;
        var cont = document.getElementsByClassName("content");
        var item = document.getElementById("navbar");
        var mbNavbar = document.getElementById("navbarMobile");

        var fixed = mbNavbar.offsetTop;
      
        

        checker = window.getComputedStyle(item).display;
    
        if(checker == "flex"){
          if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            document.getElementById("navbar").style.position = "fixed";
            for(x = 0; x < cont; x++){
              cont.style.paddingTop = "16px";
            }          
          } else {
            document.getElementById("navbar").style.position = "relative";          
            for(x = 0; x < cont; x++){
              cont.style.paddingTop = "auto";
            }
          }
        } 
        // else {
        //   if (document.body.scrollTop > 1 || document.documentElement.scrollTop > 1) {
        //     document.getElementById("navbarMobile").style.position = "fixed";
        //     for(x = 0; x < cont; x++){
        //       cont.style.paddingTop = "16px";
        //     }          
        //   } else {
        //     document.getElementById("navbarMobile").style.position = "relative";          
        //     for(x = 0; x < cont; x++){
        //       cont.style.paddingTop = "auto";
        //     }
        //   }
        // } 


        
      }

      function reveal() {
        var reveals = document.querySelectorAll(".reveal");
       
        for (var i = 0; i < reveals.length; i++) {
          var windowHeight = window.innerHeight;
          var elementTop = reveals[i].getBoundingClientRect().top;
          var elementVisible = 150;
          if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active");
          } else {
            reveals[i].classList.remove("active");
          }
        }
      }

      

      function closeMenu() {
                var x = document.getElementById("navList");
                var linkDisplay = window.getComputedStyle(x).display;                

                x.style.display = "none";    
                                
                document.getElementById("navbarMobile").style.backgroundColor = "transparent";

            }